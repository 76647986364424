import Footer from "@/components/Footer";
import Header from "@/components/Header";
import LandingPage from "@/components/Landing";
import { usePlaceholder } from "@/contexts/placeholderContext";
import { Box } from "@mui/material";

export default function IndexPage() {
  const { placeholderImages } = usePlaceholder();

  return (
    <Box width="100%" height="100%" minHeight="100%" maxHeight="100vh" display="flex" flexDirection="column">
      <Header />
        <LandingPage placeholderImages={placeholderImages} />
      <Footer />
    </Box>
  );
}
